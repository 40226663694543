import React from "react";

const Cross = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.9 225.9">
            <defs></defs>
            <g id="BRANDMARKS">
                <g>
                    <rect
                        className="cls-1"
                        x="89.93"
                        y="72.36"
                        width="7.65"
                        height="42.81"
                        transform="translate(-38.84 93.76) rotate(-45)"
                    />
                    <rect
                        className="cls-1"
                        x="64.42"
                        y="109.07"
                        width="96.95"
                        height="7.65"
                        transform="translate(-46.76 112.9) rotate(-45)"
                    />
                    <rect
                        className="cls-1"
                        x="128.21"
                        y="110.63"
                        width="7.65"
                        height="42.81"
                        transform="translate(-54.69 132.04) rotate(-45)"
                    />
                </g>
            </g>
        </svg>
    );
};

export { Cross };
