import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import tw from "twin.macro";
import { DesktopCarousel, MobileCarousel } from "../components/Carousel";
import { Responsive } from "../utils/Responsive";
import { Cross } from "../components/icons/Cross";
import { Navlink } from "../components/Typography";

const Article = ({ data }) => {
    const { title } = data.contentfulArticle;

    const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0);

    const mobileImages = data.contentfulArticle.mobileImages;
    const desktopImages = data.contentfulArticle.desktopImages;

    return (
        <CarouselLayout>
            <Responsive
                sm={
                    <MobileCarousel
                        {...{
                            mobileImages,
                            selectedImageIdx,
                            setSelectedImageIdx,
                        }}
                    />
                }
                md={
                    <DesktopCarousel
                        {...{
                            desktopImages,
                            selectedImageIdx,
                            setSelectedImageIdx,
                        }}
                    />
                }
            />
            <Menu {...{ title }} />
        </CarouselLayout>
    );
};

export default Article;

const Menu = ({ title }) => {
    return (
        <>
            <div tw="grid absolute left-0 bottom-0 py-3 2xl:py-6 px-5 2xl:px-8 ">
                <Navlink>{title}</Navlink>
            </div>
            <Nav>
                <Link to="/discover">
                    <div tw="w-14 h-14">
                        <Cross />
                    </div>
                </Link>
            </Nav>
        </>
    );
};

export { Menu };

const CarouselLayout = styled.div`
    ${tw`absolute grid w-full h-full overflow-hidden`};
`;

const Nav = styled.div`
    ${tw`py-3 2xl:py-6 px-5 2xl:px-8 fixed justify-end right-0 cursor-pointer`};
    z-index: 100;
    grid-template-columns: 1fr 1fr;
`;

export const query = graphql`
    query ($id: String) {
        contentfulArticle(id: { eq: $id }) {
            id
            title
            mobileImages {
                description
                id
                resize(width: 1000) {
                    src
                }
            }
            desktopImages {
                id
                images {
                    description
                    resize(width: 1800) {
                        src
                    }
                }
            }
        }
    }
`;
